<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div>
    <svg
      id="ecvWaveBannerTop"
      class="ecvWaveBannerTop"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 30"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        id="ecvWaveBannerTopPath"
        class="cls-1"
        d="M1920,17.31l-80-.57c-80-.62-240-1.7-400-1.16s-320,2.92-480,6.35c-160,3.5-320,8.04-480,8.07-160-.03-320-4.58-400-6.92L0,20.78V0H1920V17.31Z"
      />
    </svg>
    <!-- --------------------------- -->

    <svg
      id="ecvWaveBannerTopMobile"
      class="ecvWaveBannerTopMobile"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 428 20"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        id="ecvWaveBannerTopMobilePath"
        class="cls-1"
        d="M428,11.54l-17.83-.38c-17.83-.41-53.5-1.13-89.17-.77-35.67,.36-71.33,1.95-107,4.23-35.67,2.33-71.33,5.36-107,5.38-35.67-.02-71.33-3.05-89.17-4.62L0,13.85V0H428V11.54Z"
      />
    </svg>
  </div>
</template>

<style scoped lang="sass">
.ecvWaveBannerTop
  display: block
  width: 100vw
  @media screen and (max-width:900px)
    display: none
.ecvWaveBannerTopMobile
  display: none
  @media screen and (max-width:900px)
    display: block
    width: 100vw
.cls-1
  fill: #ffffff
</style>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
</script>
