<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div class="ecvOwlType1">
    <v-img cover :height="imgHeight" :src="computedPhotoUrl" :alt="photoName"></v-img>
    <div class="ecvOwlBox">
      <v-container
        class="
          d-flex
          align-center
          justify-space-between
          flex-column-reverse flex-md-row
          inner-container
          inner-container--content
        "
      >
        <div class="text-center text-md-left">
          <h1 class="ecvOwlTitle text-overflow-ep-3 text-overflow-ep-md-2">{{ title }}</h1>
          <p class="ecvOwlContent white--text text-overflow-ep-2 tmw-100 tmw-md-20-30">
            {{ subtitle }}
          </p>
          <Main-btn
            checkLink
            color="white"
            class="banner-btn primary--text"
            linkOut
            :to="buttonUrl"
            :targetBlank="targetBlank"
          >
            {{ buttonText }}
          </Main-btn>
        </div>
        <div v-if="thumbnailUrl" class="mb-3 mb-md-0">
          <v-img
            cover
            :width="thumbnailWidth"
            :aspect-ratio="thumbnailRatio"
            :src="thumbnailUrl"
            :alt="thumbnailName"
          >
          </v-img>
        </div>
      </v-container>
    </div>
    <div class="ecvOwlBg" :style="{ backgroundImage: bgGradient }"></div>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import bannerItem from "./bannerItem.js";

export default {
  mixins: [bannerItem],
  computed: {
     thumbnailRatio() {
      if (this.$vuetify.breakpoint.mdAndUp) return 180/120;
      return 180/120;
    },
    thumbnailWidth() {
      if(this.windowWidth > this.$vuetify.breakpoint.thresholds.sm) return 300
      return 180
    },
  },
};
</script>